import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65')
];

export const server_loads = [0,10,2,4,6,7,8,12,13,16];

export const dictionary = {
		"/(public)": [32,[10],[11]],
		"/(public)/about": [33,[10],[11]],
		"/(admin)/admin": [~17,[2],[3]],
		"/(admin)/admin/auctions": [~18,[2,4],[3]],
		"/(admin)/admin/auctions/new": [~24,[2,4],[3]],
		"/(admin)/admin/auctions/[auctionId=integer]": [~19,[2,4,5],[3]],
		"/(admin)/admin/auctions/[auctionId=integer]/bids": [~20,[2,4,5],[3]],
		"/(admin)/admin/auctions/[auctionId=integer]/files": [~21,[2,4,5],[3]],
		"/(admin)/admin/auctions/[auctionId=integer]/invoice": [~22,[2,4,5],[3]],
		"/(admin)/admin/auctions/[tab]": [~23,[2,4],[3]],
		"/(admin)/admin/bids": [~25,[2,6],[3]],
		"/(admin)/admin/branches": [~26,[2,7],[3]],
		"/(admin)/admin/branches/new": [~28,[2,7],[3]],
		"/(admin)/admin/branches/[branchId=integer]": [~27,[2,7],[3]],
		"/(admin)/admin/companies": [~29,[2,8],[3]],
		"/(admin)/admin/companies/[companyId=integer]": [~30,[2,8,9],[3]],
		"/(admin)/admin/companies/[companyId=integer]/bids": [~31,[2,8,9],[3]],
		"/(public)/auctions": [~34,[10],[11]],
		"/(public)/auctions/favorites": [~38,[10],[11]],
		"/(public)/auctions/[category=text]": [36,[10],[11]],
		"/(public)/auctions/[auctionId=integer]": [~35,[10],[11]],
		"/(public)/auctions/[category=text]/[subCategory=text]": [37,[10],[11]],
		"/(public)/auth": [~39,[12]],
		"/(public)/auth/activation": [~40,[12]],
		"/(public)/auth/forgot-password": [41,[12]],
		"/(public)/auth/login": [~42,[12]],
		"/(public)/auth/logout": [~43,[12]],
		"/(public)/auth/register": [~44,[12]],
		"/(public)/auth/reset-password": [45,[12]],
		"/(public)/auth/verify-email": [~46,[12]],
		"/(public)/backoffice": [~47,[13],[14]],
		"/(public)/backoffice/account/company": [~48,[13,15],[14]],
		"/(public)/backoffice/account/notifications": [~49,[13,15],[14]],
		"/(public)/backoffice/account/profile": [~50,[13,15],[14]],
		"/(public)/backoffice/auctions/active": [~51,[13],[14]],
		"/(public)/backoffice/my-buying": [~52,[13],[14]],
		"/(public)/backoffice/my-buying/[auctionId=integer]": [~53,[13],[14]],
		"/(public)/backoffice/my-buying/[tab]": [~54,[13],[14]],
		"/(public)/backoffice/my-selling": [~55,[13],[14]],
		"/(public)/backoffice/my-selling/new": [~62,[13],[14]],
		"/(public)/backoffice/my-selling/[auctionId=integer]": [56,[13,16],[14]],
		"/(public)/backoffice/my-selling/[auctionId=integer]/bids": [57,[13,16],[14]],
		"/(public)/backoffice/my-selling/[auctionId=integer]/buyer": [58,[13,16],[14]],
		"/(public)/backoffice/my-selling/[auctionId=integer]/duplicate": [~59,[13],[14]],
		"/(public)/backoffice/my-selling/[auctionId=integer]/files": [~60,[13,16],[14]],
		"/(public)/backoffice/my-selling/[tab]": [~61,[13],[14]],
		"/(public)/fees": [63,[10],[11]],
		"/(public)/privacy-policy": [64,[10],[11]],
		"/(public)/terms": [65,[10],[11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';