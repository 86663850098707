import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
  enabled: !dev,
  environment: dev ? 'development' : 'production',
  dsn: 'https://1f232b01bfa541f7bcc66caa753b1071@o4505363550240768.ingest.sentry.io/4505363552862208',
  // Performance Monitoring:
  tracesSampleRate: dev ? 1 : 0.2,
  // Session Replay
  integrations: [new Sentry.Replay()],
  replaysSessionSampleRate: 0.1, // This sets the Replay sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const myErrorHandler = (({ error }) => {
  console.error('Client error:', error);
}) satisfies HandleClientError;

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
